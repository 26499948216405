import { Location } from '@reach/router'
import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { addGoogleAnalyticsPageData } from '../../utils/googleAnalyticsEvents'

import Facebook from './facebook.inline.svg'
import Instagram from './instagram.inline.svg'
import Layout from '../../components/layout'
import { SocialOption } from '../../components/socialcontrols/socialcontrols.styled'
import LinkedIn from './linkedin.inline.svg'
import SEO from '../../components/seo'
import Twitter from './twitter.inline.svg'
import Youtube from './youtube.inline.svg'

const FacebookStyled = styled(Facebook)`
  path {
    fill: ${(props) => props.fill};
  }
`

const TwitterStyled = styled(Twitter)`
  path {
    fill: ${(props) => props.fill};
  }
`

const YoutubeStyled = styled(Youtube)`
  path {
    fill: ${(props) => props.fill};
  }
`

const InstagramStyled = styled(Instagram)`
  path {
    fill: ${(props) => props.fill};
  }
`

const LinkedInStyled = styled(LinkedIn)`
  path {
    fill: ${(props) => props.fill};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px;
  grid-template-rows: 32px;
  button {
    align-self: center;
  }
`

const List = styled.ul`
  margin-right: -10px;
  content: '-';
  list-style: '-';
`

const ListItem = styled.li`
  padding-right: 10px;
`

const SocialRow = () => {
  return (
    <Grid>
      <SocialOption
        onClick={() => {
          window.open('https://www.facebook.com/asharqnews')
        }}
      >
        <FacebookStyled fill="#848484" />
      </SocialOption>
      <SocialOption
        onClick={() => {
          window.open('https://www.twitter.com/asharqnews')
        }}
      >
        <TwitterStyled fill="#848484" />
      </SocialOption>
      <SocialOption
        onClick={() => {
          window.open('https://instagram.com/AsharqNews')
        }}
      >
        <InstagramStyled fill="#848484" />
      </SocialOption>
      <SocialOption
        onClick={() => {
          window.open('https://youtube.com/AsharqNews')
        }}
      >
        <YoutubeStyled fill="#848484" />
      </SocialOption>
      <SocialOption
        onClick={() => {
          window.open('https://www.linkedin.com/company/asharqnews')
        }}
      >
        <LinkedInStyled fill="#848484" />
      </SocialOption>
    </Grid>
  )
}

const TermsConditions = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Location>
      {({ location }) => (
        <Layout location={location} title={siteTitle} lang={'ar'}>
          <h1 className="customH1">{'نبذة عن الشرق للأخبار'}</h1>
          <SEO
            title="نبذة عنا | الشرق للأخبار"
            description="موقع الشرق هو موقع إخباري تابع لشركة الشرق للخدمات الإخبارية المحدودة وهي مؤسسة إعلامية مستقلة تتكون من فريق إعلامي مكون من زملاء وزميلات من مختلف الجنسيات."
            meta={[
              {
                property: `keywords`,
                content: `نبذة عنا، الشرق للآخبار، قناة الشرق ، تلفزيون الشرق ، قناة الشرق الاخبارية ، اعلام الشرق ، شبكة قنوات الشرق، , موقع اخباري, مؤسسة اعلامية, شركة اعلامية, منصة اخبارية`
              }
            ]}
            lang="ar"
            canonicalUrl={`${process.env.GATSBY_BASE_URL}ar/about/`}
          />
          <div className="small-print-page">
            <h2
              style={{
                textAlign: 'center'
              }}
            >
              عن "الشرق للأخبار"
            </h2>
            <p>
              "الشرق للأخبار" خدمة إخبارية متعددة المنصات انطلقت في 2020.11.11
              وتعمل على مدار الساعة ، مُقدمةً المحتوى الإخباري باللغة العربية
              بمقاربة إعلامية أساسها توفير الأخبار والتحليلات عبرَ منظورٍ
              اقتصادي لتمكين الناس في شؤون حياتهم اليومية.
            </p>
            <p>
              خدمات "الشرق للأخبار" تصلكم من خلال قناة تلفزيونية إخبارية متخصصة
              على مدار الساعة، و مجموعة متنوعة من المنصات الرقمية، التي توفر
              المحتوى حول حياة الناس والأحداث والجماعات والأفكار التي تؤثر على
              منطقة الشرق الأوسط وشمال أفريقيا وعلى الأسواق العالمية.
            </p>
            <p>
              "نضع النقاط" هو شعار الشرق للأخبار الذي يحرك العمل الصحفي فيها وهو
              الهدف النهائي، نقاط الشرق تربط الخبر بسياقه ومحيطه الجغرافي وبعده
              التاريخي وعمقه السياسي وأثره الاقتصادي وواقعه الاجتماعي.
            </p>
            <p>
              تشكل <strong>"اقتصاد الشرق مع بلومبِرغ"</strong> المكون الرئيس في
              باقة الخدمات التي تقدمها "الشرق للأخبار"، إذ تعتمد على اتفاقية
              حصرية من بلومبِرغ، رائدة الأخبار الاقتصادية والمالية العالمية،
              مستفيدة من تغطيتها الشاملة التي توفرها خبرات أكثر من 2700 صحفي
              ومحلل اقتصادي حول العالم.
            </p>
            <p>
              وتتطلع الشرق أن تكون خدماتها المتعلقة بالمحتوى الاقتصادي، ("اقتصاد
              الشرق مع بلومبِرغ")، المصدر الأول لأخبار الاقتصاد باللغة العربية،
              من خلال منصاتها المتعددة (التلفزيون والمنصات الرقمية)، ليصل محتوى
              الخدمة إلى قادة الأعمال، كما للأجيال الشابة الرائدة في مجالها، إذ
              تبحث عن الأخبار والمعلومات الاقتصادية والمالية والمؤسسية العالمية.
            </p>
            <p>
              فريق الاقتصاد المتخصص لدى "الشرق للأخبار"، سواء عبر القناة
              التلفزيونية أو المنصات الرقمية، يتمتع بحقوق الاستخدام الكاملة
              للمحتوى الضخم الذي توفره بلومبِرغ، والمتمثل بالمحتوى الاقتصادي
              وتحليل بيانات الأسواق، بهدف ترجمتها وإعادة تحريرها ونشرها على
              منصاته.
            </p>
            <p>
              يقع مقر "الشرق للأخبار" الرئيسي في الرياض مع مكاتب في مركز دبي
              المالي العالمي وفي واشنطن بالإضافة إلى مقرات وأستوديوهات في
              القاهرة وأبوظبي. كما لديها مجموعة من المكاتب إقليمية والمراسلين في
              العواصم والمدن الكبرى في المنطقة والعالم، بالإضافة إلى قدرة الوصول
              إلى المحتوى الذي ينتجه مئات الصحفيين والمراسلين في شبكة بلومبرغ
              حول العالم.
            </p>
            <p>
              "الشرق للأخبار" هي علامة تجارية مسجلة مملوكة من "الشرق للخدمات
              الإخبارية المحدودة"، والتي تمتلكها "المجموعة السعودية للأبحاث
              والاعلام". وتعتبر أكبر مجموعات النشر والاعلام الاخباري والمعرفي في
              الشرق الأوسط مع نحو من عشرين مطبوعة ومنصة رقمية منها صحف "الشرق
              الأوسط" والرياضية و"عرب نيوز" و"الاقتصادية" و"اندبندنت عربي"
              و"الرجل" و"سيدتي" وغيرها الكثير.
            </p>
            <p>
              في أقل من ثلاثة شهور منذ اطلاقها، حصدت "الشرق للأخبار" جائزة
              "مشروع العام الأكثر ابتكاراً في الشرق الأوسط" ضمن جوائز "برودكاست
              برو" السنوية لعام 2020، كما تم ترشيحها أيضاً عن فئة "الريادة" ضمن
              الجوائز السنوية. كما فازت شركة Clickspring التي قامت بعمل التصميم
              الداخلي المميز لمكاتب واستديوهات الشرق للأخبار في دبي، بثلاث جوائز
              مشرفة من جوائز إنتاج البث لعام 2021 (
              <a
                href="https://www.broadcastproductionawards.com/?a=Broadcast+Production+Awards"
                target="_blank"
              >
                Broadcast Production Awards
              </a>
              )، حيث حصد مشروع الشرق جائزة "المشروع الدولي للعام" وجائزة "تصنيع
              التصميم" من ضمن تلك الجوائز.
            </p>
            <p>
              وفي عام 2021 فازت الشرق للأخبار بثلاث جوائز خلال توزيع جوائز "مينا
              سيرتش" عن فئة أفضل محرك بحث باللغة العربية، وأفضل حملة متكاملة،
              وأفضل تسويق للمحتوى باللغة العربية لعام 2021. كما فازت الشرق
              بجائزتين ضمن فعاليات&nbsp;
              <a
                href="https://www.linkedin.com/feed/hashtag/?keywords=%D9%85%D9%86%D8%AA%D8%AF%D9%89_%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84_%D8%A7%D9%84%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%D9%8A_%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%85%D9%8A&amp;highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6869556290478252032"
                target="_blank"
              >
                منتدى التواصل الاجتماعي العالمي
              </a>
              &nbsp;الذي أًقيم في عمّان بالأردن، وهما جائزة أفضل مذيع أخبار لعام
              2021 من نصيب هديل عليّان، إعلامية الشرق للأخبار، وجائزة أفضل
              استخدام لتويتر ضمن حملة تسويقية. وفي عام 2022، استلمت الإعلامية
              هديل عليان جائزة أطوار بهجت في العاصمة بغداد عن فئة افضل إعلامية
              عربية ضمن حفل منتدى الإعلاميات العراقيات بمناسبة الذكرى الحادية
              عشرة على تأسيسه والذي تزامن مع ذكرى استشهاد الصحافية العراقية
              أطوار بهجت.
            </p>
            <p>
              وفي عام 2022 فازت الشرق للأخبار بتسعة من "جوائز Broadcast
              Production Awards 2022" والتي تكّرم أفضل الانتاجات في صناعة
              الأخبار والرياضة والترفيه، حيث يقدم الفريق الإبداعي في الشرق أحدث
              الحلول لعرض وسرد القصص لتأكيد رؤيتنا الاستباقية التي تحرص على ضمان
              تقديم محتوى إعلامي جذاب.
            </p>
            <p>
              <strong>
                حسابات "اقتصاد الشرق مع بلومبرغ" على منصات التواصل الاجتماعي:
              </strong>
            </p>
            <ul>
              <li>
                تويتر:{' '}
                <a href="https://twitter.com/asharqbusiness/" target="blank">
                  https://twitter.com/asharqbusiness
                </a>
              </li>
              <li>
                يوتيوب:{' '}
                <a
                  href="https://www.youtube.com/channel/UCxjpGbfoLy6oodYdiyzQE4g"
                  target="blank"
                >
                  https://www.youtube.com/channel/AsharqBusiness
                </a>
              </li>
              <li>
                فيسبوك:{' '}
                <a
                  href="https://www.facebook.com/AsharqBusiness/"
                  target="_blank"
                >
                  https://www.facebook.com/AsharqBusiness
                </a>
              </li>
              <li>
                انستغرام:{' '}
                <a
                  href="https://www.instagram.com/asharqbusiness/"
                  target="_blank"
                >
                  https://www.instagram.com/asharqbusiness
                </a>
              </li>
              <li>
                لينكدإن:{' '}
                <a
                  href="https://www.linkedin.com/showcase/asharq-business-%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF-%D8%A7%D9%84%D8%B4%D8%B1%D9%82/"
                  target="_blank"
                >
                  https://AsharqBusiness
                </a>
              </li>
              <li>
                اقتصاد الشرق بالأخضر:
                <ul>
                  <li>
                    تويتر:{' '}
                    <a href="https://twitter.com/asharqgreen" target="_blank">
                      https://twitter.com/asharqgreen
                    </a>
                  </li>
                  <li>
                    فيسبوك:{' '}
                    <a
                      href="https://www.facebook.com/AsharqGreen/videos/"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqGreen
                    </a>
                  </li>
                  <li>
                    انستغرام{' '}
                    <a href="https://instagram.com/asharqgreen" target="_blank">
                      https://instagram.com/asharqgreen
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                اقتصاد الشرق تيكنولوجيا:
                <ul>
                  <li>
                    <u>تويتر: </u>
                    <a href="https://twitter.com/asharqtech" target="_blank">
                      https://twitter.com/asharqtech
                    </a>
                  </li>
                  <li>
                    فيسبوك:{' '}
                    <a
                      href="https://www.facebook.com/AsharqTech/"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqTech
                    </a>
                  </li>
                  <li>
                    <u>انستغرام: </u>
                    <a
                      href="https://www.instagram.com/asharqtech/?hl=en"
                      target="_blank"
                    >
                      https://www.instagram.com/asharqtech/?hl=en
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                اقتصاد الشرق- رياضة:
                <ul>
                  <li>
                    تويتر:{' '}
                    <a href="https://twitter.com/AsharqBSP" target="_blank">
                      https://twitter.com/AsharqBSP
                    </a>
                  </li>
                  <li>
                    فيسبوك:{' '}
                    <a
                      href="https://www.facebook.com/AsharqBSP"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqBSP
                    </a>
                  </li>
                  <li>
                    انستغرام:{' '}
                    <a
                      href="https://www.instagram.com/AsharqBSP"
                      target="_blank"
                    >
                      https://www.instagram.com/AsharqBSP
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                اقتصاد الشرق- كريبتو:
                <ul>
                  <li>
                    تويتر:{' '}
                    <a
                      href="https://www.twitter.com/AsharqCrypto"
                      target="_blank"
                    >
                      https://www.twitter.com/AsharqCrypto
                    </a>
                  </li>
                  <li>
                    فيسبوك:{' '}
                    <a
                      href="https://www.facebook.com/AsharqCrypto"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqCrypto
                    </a>
                  </li>
                  <li>
                    انستغرام:{' '}
                    <a
                      href="https://www.instagram.com/AsharqCrypto"
                      target="_blank"
                    >
                      https://www.instagram.com/AsharqCrypto
                    </a>
                  </li>
                  <li>
                    بزنس ويك
                    <ul>
                      <li>
                        تويتر:{' '}
                        <a href="https://twitter.com/asharqbw" target="_blank">
                          https://twitter.com/asharqbw
                        </a>
                      </li>
                      <li>
                        فيسبوك:{' '}
                        <a
                          href="https://www.facebook.com/AsharqBW/community/?ref=page_internal"
                          target="_blank"
                        >
                          https://www.facebook.com/AsharqBW
                        </a>
                      </li>
                      <li>
                        انستغرام:{' '}
                        <a
                          href="https://instagram.com/asharqbw"
                          target="_blank"
                        >
                          https://instagram.com/asharqbw
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    اقتصاد الشرق &ndash; رأي
                    <ul>
                      <li>
                        تويتر:{' '}
                        <a href="https://twitter.com/asharqbop" target="_blank">
                          https://twitter.com/asharqbop
                        </a>
                      </li>
                      <li>
                        فيسبوك:{' '}
                        <a
                          href="https://www.facebook.com/AsharqBOP/"
                          target="_blank"
                        >
                          https://www.facebook.com/AsharqBOP
                        </a>
                      </li>
                      <li>
                        انستغرام:{' '}
                        <a
                          href="https://www.instagram.com/asharqbop/"
                          target="_blank"
                        >
                          https://www.instagram.com/asharqbop
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>
                حسابات "الشرق للأخبار" على منصات التواصل الاجتماعي:
              </strong>
            </p>
            <ul>
              <li>
                تويتر:{' '}
                <a href="https://twitter.com/AsharqNews" target="_blank">
                  https://twitter.com/AsharqNews
                </a>
              </li>
              <li>
                يوتيوب:{' '}
                <a href="https://www.youtube.com/c/asharqnews" target="_blank">
                  https://www.youtube.com/c/asharqnews
                </a>
              </li>
              <li>
                فيسبوك:{' '}
                <a href="https://www.facebook.com/asharqnews/" target="_blank">
                  https://www.facebook.com/asharqnews
                </a>
              </li>
              <li>
                انستغرام:
                <a href="https://www.instagram.com/asharqnews/" target="_blank">
                  https://www.instagram.com/asharqnews
                </a>
              </li>
              <li>
                تيك توك:{' '}
                <a
                  href="https://www.tiktok.com/@asharqnews?lang=en"
                  target="_blank"
                >
                  https://www.tiktok.com/@asharqnews?lang=en
                </a>
              </li>
              <li>
                الأخبار العاجلة:
                <ul>
                  <li>
                    تويتر:{' '}
                    <a href="https://twitter.com/asharqnewsbrk" target="_blank">
                      https://twitter.com/asharqnewsbrk
                    </a>
                  </li>
                  <li>
                    فيسبوك:{' '}
                    <a
                      href="https://facebook.com/asharqnewsbrk"
                      target="_blank"
                    >
                      https://facebook.com/asharqnewsbrk
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>منصات "الشرق للأخبار" الرقمية</strong>
              <strong>:</strong>
            </p>
            <ul>
              <li>
                <strong>الموقع الإلكتروني للشرق للأخبار: </strong>
                <ul>
                  <li>
                    <a href="https://asharq.com/" target="_blank">
                      https://asharq.com
                    </a>
                  </li>
                  <li>
                    <a href="https://asharq.com/platforms/" target="_blank">
                      https://asharq.com/platforms
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <strong>الموقع الإلكتروني "لاقتصاد الشرق مع بلومبرغ"</strong>
                <ul>
                  <li>
                    <a href="http://asharqbusiness.com/" target="_blank">
                      http://asharqbusiness.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.asharqbusiness.com/platforms"
                      target="_blank"
                    >
                      https://www.asharqbusiness.com/platforms
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>تطبيقات الشرق:</strong>
            </p>
            <ul>
              <li>
                تطبيق "اقتصاد الشرق":{' '}
                <a href="http://onelink.to/mkd3vw" target="_blank">
                  http://onelink.to/mkd3vw
                </a>
              </li>
              <li>
                تطبيق "الشرق للأخبار":{' '}
                <a href="http://onelink.to/6ftybm" target="_blank">
                  http://onelink.to/6ftybm
                </a>
              </li>
              <li>
                الشرق NOW:{' '}
                <a href="http://onelink.to/guh86v" target="_blank">
                  http://onelink.to/guh86v
                </a>
              </li>
            </ul>
            <p>
              <strong>خدمة الفيديو عند الطلب</strong>
              <strong>:</strong>
            </p>
            <ul>
              <li>
                <a href="https://now.asharq.com/" target="_blank">
                  https://now.asharq.com
                </a>
              </li>
            </ul>
            <p>
              <strong>
                حسابات "الشرق للأخبار" المؤسسية على منصات التواصل الاجتماعي:
              </strong>
            </p>
            <ul>
              <li>
                تويتر:{' '}
                <a href="https://twitter.com/AsharqCorp" target="_blank">
                  https://twitter.com/AsharqCorp
                </a>
              </li>
              <li>
                لينكدإن:{' '}
                <a
                  href="https://www.linkedin.com/company/asharqnews"
                  target="_blank"
                >
                  https://www.linkedin.com/company/asharqnews
                </a>
              </li>
            </ul>
            <p>منصاتنا:</p>
            <SocialRow />
          </div>
          <div dir="ltr" className="small-print-page">
            <h2 style={{ textAlign: 'center' }}>About Asharq News </h2>

            <p>
              Asharq News, launched on 11.11.2020, is a 24/7, multiplatform
              Arabic news service reaching across the Arab world and beyond with
              a unique approach: news and in-depth analysis reported through the
              prism of the economy to empower people in their everyday lives.
            </p>
            <p>
              The Asharq News experience is delivered through a dedicated
              television channel, as well as multiple digital platforms,
              offering continuous insights into the people, events,
              organisations and ideas that impact the MENA region and
              international markets.
            </p>
            <p>
              An exclusive content agreement with Bloomberg Media, the business
              and financial information news leader, powers a key component,
              &ldquo;
              <strong>
                <em>Asharq Business with Bloomberg</em>
              </strong>
              <em>&rdquo;</em>, drawing on Bloomberg&rsquo;s comprehensive
              coverage from more than 2,700 journalists and analysts globally.
            </p>
            <p>
              &ldquo;Asharq Business with Bloomberg&rdquo; aims to become the
              premier Arabic-language business news provider, using multiple
              platforms to reach business leaders, as well as a younger
              generation that understands the importance of global economic,
              financial and corporate information. With this collaboration,
              Asharq Business teams (broadcast and digital) have access to
              Bloomberg&rsquo;s extensive financial and economic content,
              analysis and market data, curating translated stories from
              Bloomberg to our platforms.
            </p>
            <p>
              &lsquo;Connecting the Dots&rsquo; is Asharq News slogan that
              drives its journalistic work and sets its ultimate goal. The dots
              at Asharq News connect the news to its context, geographical
              setting, historical dimension, political depth, economic impact
              and social reality. Asharq News targets decision makers,
              businesspeople, entrepreneurs, and political and technology
              leaders through its economic content, as it addresses all aspects
              of political developments from an economic perspective.
            </p>
            <p>
              Asharq News is headquartered in Riyadh, with offices in the Dubai
              International Financial Centre, UAE, and Washington D.C., and has
              major hubs and studios in Cairo and Abu Dhabi. It also has
              regional offices and correspondents across key Arab countries and
              in global capitals, in addition to access to the content produced
              by hundreds of reporters from Bloomberg's network around the
              world.
            </p>
            <p>
              Asharq News is owned by the Saudi Research and Media Group (SRMG)
              the Middle East&rsquo;s largest publishing group and only publicly
              listed media company, with more than 16 publications and digital
              platforms providing the latest in political, financial, sports,
              and lifestyle news and analysis.&nbsp;
            </p>
            <p>
              Within its first three months of launch, Asharq News was awarded
              &lsquo;ME Innovative Project of the year&rsquo; 2020- at the
              annual BroadcastPro Annual Summit &amp; Awards and shortlisted as
              trendsetter. Furthermore, our design partner
            </p>
            <p>
              Clickspring&nbsp;Design won three categories and received three
              honorable mentions in this year&rsquo;s{' '}
              <a
                href="https://www.broadcastproductionawards.com?a=Broadcast+Production+Awards"
                target="_blank"
              >
                Broadcast Production Awards
              </a>
              Asharq News Dubai Facility won &lsquo;International Set of the
              Year&rsquo; and &lsquo;Set Design Fabrication&rsquo;.
            </p>
            <p>
              In 2021, Asharq News has also won three awards at the &lsquo;MENA
              Search Awards&rsquo; including: Best Arabic SEO, Best Integrated
              Campaign and Best Use of Content Marketing in Arabic. Asharq News
              has also won two awards at the World Social Media Forum, held in
              Amman, Jordan in 2021: The Best TV Anchor of the Year for Asharq
              News Anchor Hadil Eleyan and The Best Use of Twitter for PR
              Campaign Award. In 2022, Hadil Eleyan also received the Atwar
              Bahjat Award in Baghdad, for the category of the Best Arab Media
              Anchor, within the celebration held by the Iraqi Women Journalists
              Forum on the occasion of the eleventh anniversary of its founding,
              which coincided with the anniversary of the martyrdom of the Iraqi
              journalist Atwar Bahjat.
            </p>
            <p>
              In 2022, Asharq News won 9 awards from
              NewscastStudio&rsquo;s&nbsp;Broadcast Production Awards, that
              acknowledges the best in creative production and technical
              achievement across news, sports and entertainment.
            </p>
            <p>
              <strong>
                Asharq Business with Bloomberg accounts on social media:
              </strong>
            </p>
            <ul>
              <li>
                Twitter<strong>: </strong>
                <a href="https://twitter.com/asharqbusiness" target="_blank">
                  https://twitter.com/asharqbusiness
                </a>
              </li>
              <li>
                YouTube<strong>: </strong>
                <a
                  href="https://www.youtube.com/channel/AsharqBusiness"
                  target="_blank"
                >
                  https://www.youtube.com/channel/AsharqBusiness
                </a>
              </li>
              <li>
                Facebook:{' '}
                <a
                  href="https://www.facebook.com/AsharqBusiness"
                  target="_blank"
                >
                  https://www.facebook.com/AsharqBusiness
                </a>
              </li>
              <li>
                Instagram<strong>: </strong>
                <a
                  href="https://www.instagram.com/asharqbusiness"
                  target="_blank"
                >
                  https://www.instagram.com/asharqbusiness
                </a>
              </li>
              <li>
                LinkedIn<strong>: </strong>
                <a
                  href="https://www.linkedin.com/showcase/asharq-business-%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF-%D8%A7%D9%84%D8%B4%D8%B1%D9%82/"
                  target="_blank"
                >
                  https://AsharqBusiness
                </a>
              </li>
              <li>
                Business Week:{' '}
                <a href="https://instagram.com/asharqbw" target="_blank">
                  https://instagram.com/asharqbw
                </a>
              </li>
              <li>
                Green<strong>: </strong>
                <ul>
                  <li>
                    Asharq Green FB (@AsharqGreen)
                    <br />
                    <a
                      href="https://www.facebook.com/AsharqGreen/videos/"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqGreen
                    </a>
                  </li>
                  <li>Asharq Green IG (@AsharqGreen)</li>
                  <li>
                    <a
                      href="https://www.instagram.com/asharqgreen/?hl=en"
                      target="_blank"
                    >
                      https://www.instagram.com/asharqgreen
                    </a>
                  </li>
                  <li>Asharq Green TW (@AsharqGreen)</li>
                  <li>
                    <a href="https://twitter.com/asharqgreen" target="_blank">
                      https://twitter.com/asharqgreen
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Tech<strong>: </strong>
                <ul>
                  <li>Asharq Tech FB (@AsharqTech)</li>
                  <li>
                    <a
                      href="https://www.facebook.com/AsharqTech/"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqTech
                    </a>
                  </li>
                  <li>
                    Asharq Tech IG (@AsharqTech)
                    <br />
                    <a
                      href="https://www.instagram.com/asharqtech/?hl=en"
                      target="_blank"
                    >
                      https://www.instagram.com/asharqtech/?hl=en
                    </a>
                  </li>
                  <li>
                    Asharq Tech TW (@AsharqTech)
                    <br />
                    <a href="https://twitter.com/asharqtech" target="_blank">
                      https://twitter.com/asharqtech
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Business Sport:
                <ul>
                  <li>Asharq BSP TW (@AsharqBSP)</li>
                  <li>
                    <a href="https://www.twitter.com/AsharqBSP" target="_blank">
                      https://www.twitter.com/AsharqBSP
                    </a>
                  </li>
                  <li>Asharq BSP FB (@AsharqBSP)</li>
                  <li>
                    <a
                      href="https://www.facebook.com/AsharqBSP"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqBSP
                    </a>
                  </li>
                  <li>Asharq BSP IG (@AsharqBSP)</li>
                  <li>
                    <a
                      href="https://www.instagram.com/AsharqBSP"
                      target="_blank"
                    >
                      https://www.instagram.com/AsharqBSP
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Crypto:
                <ul>
                  <li>Asharq Crypto TW (@AsharqCrypto)</li>
                  <li>
                    <a
                      href="https://www.twitter.com/AsharqCrypto"
                      target="_blank"
                    >
                      https://www.twitter.com/AsharqCrypto
                    </a>
                  </li>
                  <li>Asharq Crypto FB (@AsharqCrypto)</li>
                  <li>
                    <a
                      href="https://www.facebook.com/AsharqCrypto"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqCrypto
                    </a>
                  </li>
                  <li>Asharq Crypto IG (@AsharqCrypto)</li>
                  <li>
                    <a
                      href="https://www.instagram.com/AsharqCrypto"
                      target="_blank"
                    >
                      https://www.instagram.com/AsharqCrypto
                    </a>
                  </li>
                </ul>
              </li>
              <li>Business Week:</li>
              <ul>
                <li>Asharq BW FB (@AsharqBW)</li>
                <li>
                  <a
                    href="https://www.facebook.com/AsharqBW/community/?ref=page_internal"
                    target="_blank"
                  >
                    https://www.facebook.com/AsharqBW
                  </a>
                </li>
                <li>Asharq BW IG (@AsharqBW)</li>
                <li>
                  <a href="https://www.instagram.com/AsharqBW" target="_blank">
                    https://www.instagram.com/AsharqBW
                  </a>
                </li>
                <li>Asharq BW TW (@AsharqBW)</li>
                <li>
                  <a href="https://twitter.com/asharqbw" target="_blank">
                    https://twitter.com/asharqbw
                  </a>
                </li>
              </ul>

              <li>
                Opinion:
                <ul>
                  <li>Asharq BOP FB (@AsharqBOP)</li>
                  <li>
                    <a
                      href="https://www.facebook.com/AsharqBOP/"
                      target="_blank"
                    >
                      https://www.facebook.com/AsharqBOP
                    </a>
                  </li>
                  <li>Asharq BOP IG (@AsharqBOP)</li>
                  <li>
                    <a
                      href="https://www.instagram.com/asharqbop/"
                      target="_blank"
                    >
                      https://www.instagram.com/asharqbop
                    </a>
                  </li>
                  <li>Asharq BOP TW (@AsharqBOP)</li>
                  <li>
                    <a href="https://twitter.com/asharqbop" target="_blank">
                      https://twitter.com/asharqbop
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>Asharq News accounts on social media:</strong>
            </p>
            <ul>
              <li>Asharq News FB (@AsharqNews):</li>
              <li>
                <a href="https://www.facebook.com/asharqnews" target="_blank">
                  https://www.facebook.com/asharqnews
                </a>
              </li>
              <li>Asharq News TW (@AsharqNews):</li>
              <li>
                <a href="https://twitter.com/AsharqNews" target="_blank">
                  https://twitter.com/AsharqNews
                </a>
              </li>
              <li>Asharq News IG (@AsharqNews):</li>
              <li>
                <a
                  href="https://www.instagram.com/asharqnews/?hl=en"
                  target="_blank"
                >
                  https://www.instagram.com/asharqnews/?hl=en
                </a>
              </li>
              <li>Asharq News YT (@AsharqNews):</li>
              <li>
                <a href="https://www.youtube.com/c/asharqnews" target="_blank">
                  https://www.youtube.com/c/asharqnews
                </a>
              </li>
              <li>Asharq News TT (@AsharqNews):</li>
              <li>
                <a
                  href="https://www.tiktok.com/@asharqnews?lang=en"
                  target="_blank"
                >
                  https://www.tiktok.com/@asharqnews?lang=en
                </a>
              </li>
              <li>Asharq News Breaking TW (@AsharqNewsBrk)</li>
              <li>
                <a href="https://twitter.com/asharqnewsbrk" target="_blank">
                  https://twitter.com/asharqnewsbrk
                </a>
              </li>
              <li>Asharq News Breaking FB (@AsharqNewsBrk)</li>
              <li>
                <a href="https://facebook.com/asharqnewsbrk" target="_blank">
                  https://facebook.com/asharqnewsbrk
                </a>
              </li>
            </ul>
            <p>
              <strong>Asharq News Digital Platforms:</strong>
            </p>
            <ul>
              <li>
                <strong>Asharq News Website:</strong>
                <ul>
                  <li>
                    <a href="https://asharq.com/" target="_blank">
                      https://asharq.com
                    </a>
                  </li>
                  <li>
                    <a href="https://asharq.com/platforms/" target="_blank">
                      https://asharq.com/platforms
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Asharq Business with Bloomberg Website:</strong>
                <ul>
                  <li>
                    <a href="http://asharqbusiness.com/" target="_blank">
                      http://asharqbusiness.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.asharqbusiness.com/platforms"
                      target="_blank"
                    >
                      https://www.asharqbusiness.com/platforms
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>Asharq Apps:</strong>
            </p>
            <ul>
              <li>
                Asharq Business App:{' '}
                <a href="http://onelink.to/mkd3vw" target="_blank">
                  http://onelink.to/mkd3vw
                </a>
              </li>
              <li>
                Asharq News App:{' '}
                <a href="http://onelink.to/6ftybm" target="_blank">
                  http://onelink.to/6ftybm
                </a>
              </li>
              <li>
                Asharq NOW<strong>: </strong>
                <a href="http://onelink.to/guh86v" target="_blank">
                  http://onelink.to/guh86v
                </a>
              </li>
            </ul>
            <p>
              <strong>Video on demand service:</strong>
            </p>
            <ul>
              <li>
                <a href="https://now.asharq.com/" target="_blank">
                  https://now.asharq.com
                </a>
              </li>
            </ul>
            <p>
              <strong>
                Asharq News institutional accounts on social media:
              </strong>
            </p>
            <ul>
              <li>
                Twitter:{' '}
                <a href="https://twitter.com/AsharqCorp" target="_blank">
                  https://twitter.com/AsharqCorp
                </a>
              </li>
              <li>
                LinkedIn:{' '}
                <a
                  href="https://www.linkedin.com/company/asharqnews/mycompany/"
                  target="_blank"
                >
                  https://www.linkedin.com/company/asharqnews
                </a>
              </li>
            </ul>

            <p>Our digital platforms:</p>
            <SocialRow />
          </div>
        </Layout>
      )}
    </Location>
  )
}

export default TermsConditions

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
